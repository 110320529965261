/* eslint-disable camelcase */
import React, { lazy, Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Checkbox, NotFound, Select } from '../Shared';
import ProductGallery from '../ProductGallery';

import { productShape } from '../../shapes';

import './index.scss';

const ProductView = ({
  checked,
  handleProductEdit,
  handleSelectItems,
  isSelectedAll,
  items,
  onChange,
  onSortChange,
  renderColumns,
  selectAll,
  locationSearch,
  selected,
  ...props
}) => {
  const Table = lazy(() => import('../Shared/Table'));
  const FilterSidebar = lazy(() => import('../FilterSidebar'));

  const renderProductGallery = useMemo(() => items.map((product) => (
    <ProductGallery
      key={product.id}
      product={product}
      onEdit={handleProductEdit}
      onSelect={handleSelectItems}
      selected={selected}
      handleSelectItems={handleSelectItems}
    />
  )), [items, selected.length]);

  return (
    <div className="products-page">
      <FilterSidebar source="products" {...props} urlSearch={locationSearch} />
      {items.length ? (
        <div className="products-page__content">
          <div className="products-page__row">
            <div className="products-page-view">
              Gallery view
              <Switch
                className="products-page-switch"
                onChange={onChange}
                checked={checked}
                onColor="#888"
                uncheckedIcon={false}
                checkedIcon={false}
              />
              Table view
            </div>
            {!checked && (
              <div className="products-page__select-container">
                <span className="products-page__select-text">Sort:</span>
                <Select
                  className="products-page__select"
                  onChange={(event) => {
                    onSortChange(event.target.value);
                  }}
                  value={props.state.sort?.replace('-', '')}
                  name="sort"
                  options={[
                    {
                      text: 'Name',
                      value: 'name',
                    },
                    {
                      text: 'Width',
                      value: 'width',
                    },
                    {
                      text: 'Height',
                      value: 'height',
                    },
                    {
                      text: 'Depth',
                      value: 'depth',
                    },
                    {
                      text: 'Stock Level',
                      value: 'stock_number',
                    },
                    {
                      text: 'Price (USD)',
                      value: 'cost_usd',
                    },
                    {
                      text: 'Price (CAD)',
                      value: 'cost_cad',
                    },
                    {
                      text: 'New',
                      value: 'updated_at',
                    },
                  ]}
                />
                <FontAwesomeIcon
                  icon={!props.state.sort?.includes('-') ? faSortDown : faSortUp}
                  className={`products-page__select-sort
                ${!props.state.sort?.includes('-')
                ? 'down' : 'up'}`}
                  onClick={() => {
                    const { sort } = props.state;

                    onSortChange(sort?.includes('-')
                      ? sort.slice(1) : `-${sort}`);
                  }}
                />
              </div>
            )}
            {!checked && (
              <div className="products-page__check-all">
                <span className="products-page__check-all-text">Select all</span>
                <Checkbox controlFunc={selectAll} value={isSelectedAll} />
              </div>
            )}
          </div>
          <div className="products-page-content-gallery">
            <Suspense fallback={<div>Loading...</div>}>
              {checked ? (
                <Table
                  type="products"
                  noResults={false}
                  tableLoaded
                  renderColumns={renderColumns}
                  items={items}
                  selected={selected}
                  handleSelectItems={handleSelectItems}
                  onSortChange={onSortChange}
                />
              ) : renderProductGallery}
            </Suspense>
          </div>
        </div>
      ) : (<NotFound />)}
    </div>
  );
};


export default ProductView;

ProductView.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleProductEdit: PropTypes.func.isRequired,
  handleSelectItems: PropTypes.func.isRequired,
  isSelectedAll: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(productShape).isRequired,
  onChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  renderColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectAll: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
};
