import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import equal from 'deep-equal';
import { toastr } from 'react-redux-toastr';

import qs from '../../modules/normalizingQs';
import { getProducts, productsSelector } from '../../ducks/products';
import { getAvailableTemplateAction } from '../../ducks/availableTemplates';
import { filterSelector, getFilterOptions } from '../../ducks/filter';
import { addProductsToVariantData } from '../../api';
import { closeModal, showModal } from '../../ducks/modal';

import FilteringSection from '../../Components/Filtering';
import TopSection from '../../Components/Shared/TopSection';
import Pagination from '../../Components/Shared/Pagination';
import Spinner from '../../Components/Shared/Spinner';

import { WAIT_INTERVAL } from '../../constants';
import { currentRoomTypeIdSelector, saveRoomTypeId } from '../../ducks/room';
import ProductView from '../../Components/ProductView';

const renderColumns = [
  'checkbox',
  'thumbnail_image',
  'name',
  'supplier',
  'sku',
  'cost_usd',
  'cost_cad',
  'width',
  'depth',
  'height',
  'last_updated_date',
  'stock_number',
];

const basicStateValues = {
  categories: [],
  colors: [],
  countries: [],
  expandedCategories: [],
  filter_currency: '',
  price_ranges: [0, 10000],
  q: undefined,
  selected: [],
  sort: '-updated_at',
  suppliers: [],
};

const breadcrumbsItems = [
  {
    name: 'shoppe',
    to: '/',
  },
  {
    name: 'rooms',
    to: '/rooms',
  },
];

export class AddProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...basicStateValues,
      checked: false,
      isSelectedAll: false,
      modalIsOpen: false,
      params: {
        page: this.props.history.location.search,
      },
      q: undefined,
      sort: undefined,
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.push('/login');
    }

    window.scrollTo(0, 0);

    this.setState({
      price_ranges: this.convertPricesFromBE(this.props.price_ranges),
    });

    const { getProducts, getFilterOptions } = this.props;

    const {
      history: {
        location: { search },
      },
    } = this.props;
    const actualFilters = this.getQueryParamsFromUrl();

    this.setState((prevState) => ({
      ...prevState,
      price_ranges: [parseInt(actualFilters.price_ranges?.[0], 10), parseInt(actualFilters.price_ranges?.[1], 10)],
      ...actualFilters,
    }));
    const requestParams = `/products${search || `?${qs.stringify({
      page: 1,
      per_page: actualFilters.per_page,
      sort: actualFilters.sort,
    })}`}`;
    // Clear the selected products array
    this.changeSelected([]);
    // Fill filters from the querystring
    getFilterOptions();
    getProducts(requestParams);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilter(nextProps);
    this.setCurrentPage(nextProps);
  }

  getQueryParamsFromUrl = () => {
    const urlParse = qs.parse(this.props.history.location.search);

    return {
      categories: urlParse?.categories || [],
      colors: urlParse?.colors || [],
      countries: urlParse?.countries || [],
      filter_currency: urlParse?.filter_currency || undefined,
      in_stock: urlParse?.in_stock || false,
      page: urlParse?.page || 1,
      per_page: urlParse?.perPage || 30 || this.getPerPage(),
      price_ranges: urlParse?.price_ranges || [],
      q: urlParse?.q,
      sort: urlParse?.sort || '-updated_at',
      suppliers: urlParse?.suppliers || [],
    };
  };

  getPerPage = () => {
    const { products } = this.props;

    return products.loaded && products.productsData.meta.perPage;
  };

  onViewAll = () => {
    const { getProducts, push, location } = this.props;
    const perPage = this.getPerPage();

    this.setState({
      ...this.state,
      categories: [],
      colors: [],
      countries: [],
      filter_currency: '',
      price_ranges: [],
      q: undefined,
      selected: [],
      sort: undefined,
      suppliers: [],
    });

    push(`${location.pathname}?page=1&per_page=${perPage}${location.hash}`);
    getProducts(`/products?page=1&per_page=${perPage}`);
  };

  onStockAvailability = (event) => {
    this.setState(() => ({ in_stock: event }));

    this.onParamsChange({ in_stock: event });
  };

  inStockParamsUrl = () => {
    const { in_stock } = this.getQueryParamsFromUrl();

    return (in_stock === 'true');
  };

  onAddProducts = async () => {
    await this.setState({
      isDisabled: true,
    });
    const toasterSuccess = 'Success, the products successfully added';
    const toasterError = "Error, the products wasn't added";

    const { selected } = this.state;
    const products = selected.map((item) => ({ [`${item}`]: 1 }));
    const {
      match: {
        params: { vid },
      },
      design_room_id,
      saveRoomTypeId,
    } = this.props;
    const payload = {
      design_room_id,
      products,
      vid,
    };
    // TODO: Add spinner
    addProductsToVariantData(payload)
      .then((response) => {
        toastr.success(toasterSuccess);

        if (this.props.prevUrl.includes('/rooms/')) {
          const { id } = this.props.match.params;
          const { vid } = this.props.match.params;
          saveRoomTypeId(null);
          this.props.push(`/rooms/${id}/variant/${vid}`);
        } else {
          this.props.push(this.props.prevUrl);
        }
      })
      .catch((error) => toastr.error(toasterError));
  };

  onParamsChange = (params) => {
    const {
      push,
      location: { pathname, hash },
    } = this.props;
    const queryParams = qs.stringify({ ...this.getQueryParamsFromUrl(), ...params });
    push(`${pathname}?${queryParams}${hash}`);
  };

  handleSearchChange = (q) => {
    clearTimeout(this.timer);

    this.setState({ q: q?.length ? q : undefined });

    this.timer = setTimeout(this.onSearchChange, WAIT_INTERVAL);
  };

  onSearchChange = () => {
    const { q } = this.state;
    this.onParamsChange({ q });
  };

  onSortChange = (sort) => {
    this.setState(() => ({ sort }));
    this.onParamsChange({ sort });
  };

  onPageChange = (page) => {
    this.setState(() => ({ page }));
    this.onParamsChange({ page });
  };

  onPageAmountChange = (perPage) => {
    this.onParamsChange({ page: 1, per_page: perPage });
  };

  changeSelected = (items) => {
    this.setState(() => ({ selected: items }));
  };

  applyFilters = (val) => {
    const { filter_currency, price_ranges } = val;
    const newCurrency = price_ranges?.length ? filter_currency : undefined;

    this.onParamsChange({ ...val, filter_currency: newCurrency, page: 1 });
  };

  handleProductEdit = (productId) => {
    window.open(`/products/${productId}`);
  };

  handleChange = () => {
    this.setState((prevState) => ({
      checked: !prevState.checked,
    }));
  };

  setFilter(nextProps) {
    const { search } = nextProps.history.location;
    this.setState(() => ({
      params: {
        page: search,
      },
      q: qs.parse(search).q || undefined,
    }));
  }

  setCurrentPage(nextProps) {
    const { page } = this.state.params;
    const { search } = nextProps.history.location;

    if (!equal(page, search)) {
      const requestParams = `/products${search}`;
      this.props.getProducts(requestParams);
    }
  }

  convertPricesFromBE = (price_ranges) => {
    const results = [];
    let i = 0;

    if (price_ranges?.[i] === '900') {
      results.push(price_ranges?.[0]);
      i += 1;
    }
    for (i; i < price_ranges?.length; i += 2) {
      if (price_ranges[i]) {
        results.push(`${price_ranges[i]}-${price_ranges[i + 1]}`);
      }
    }
    return results;
  };

  render() {
    const { params, selected, q } = this.state;
    const {
      history: {
        location: { search },
      },
      products,
      filters,
    } = this.props;

    const pageLoaded = products.loaded && filters.loaded;

    const in_stock_params_url = this.inStockParamsUrl();

    const { checked, isSelectedAll } = this.state;

    const pages = pageLoaded && Math.ceil(products.productsData.meta.totalEntries / products.productsData.meta.perPage);

    const handleSelect = (id, val) => {
      let selectedItems = selected;
      if (val) {
        selectedItems.push(id);
      } else {
        selectedItems = selectedItems.filter((user) => user !== id);
      }
      this.changeSelected(selectedItems);
    };

    const saveChanges = (state) => {
      const {
        categories, colors, countries, filter_currency, suppliers, price_ranges,
      } = state;
      const filterState = {
        categories,
        colors,
        countries,
        expandedCategories: [],
        filter_currency: filter_currency || 'USD',
        price_ranges: price_ranges?.[0] ? price_ranges : [],
        suppliers,
      };

      this.setState(filterState);
      this.applyFilters(filterState);
    };

    const selectAll = () => {
      this.setState((prevState) => ({
        ...prevState,
        isSelectedAll: !prevState.isSelectedAll,
      }));

      if (!isSelectedAll) {
        products.productData.data.map((item) => handleSelect(item.id, !isSelectedAll));
      } else {
        this.changeSelected([]);
      }
    };

    if (!pageLoaded) {
      return (
        <div className="products-page">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="products-page">
        <div className="products-page-content">
          <TopSection
            pageTitle="add product to the variant"
            breadcrumbs={breadcrumbsItems}
            onRemoveItem={null}
            isDisabled
            onViewAll={this.onViewAll}
            shouldCreate={false}
            onStockAvailability={this.onStockAvailability}
            shouldInStock={this.state.in_stock || in_stock_params_url}
            shouldImport={false}
            shouldAdd
            onAddProducts={this.onAddProducts}
            isAddDisabled={this.state.isDisabled}
          />
          <FilteringSection
            page={params}
            q={q || ''}
            disableFilter
            onSearchChange={this.handleSearchChange}
            perPage={products.productsData.meta.perPage}
            activePage={+qs.parse(search).page || 1}
            totalEntriesAmount={products.productsData.meta.totalEntries}
            pagesAmount={products.productsData.meta.perPage}
            onPageAmountChange={this.onPageAmountChange}
          />
          <ProductView
            {...this.props}
            state={this.state}
            saveChanges={saveChanges}
            checked={checked}
            filters={filters?.filter?.data}
            handleProductEdit={this.handleProductEdit}
            handleSelectItems={this.changeSelected}
            isSelectedAll={isSelectedAll}
            items={products.productsData?.data}
            onChange={this.handleChange}
            onSortChange={this.onSortChange}
            renderColumns={renderColumns}
            selectAll={selectAll}
            selected={selected}
          />
          <Pagination
            pages={pages}
            activePage={+qs.parse(search).page}
            onPageChange={(page) => this.onPageChange(page)}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    products, filter, authReducer, routeReducer, room,
  }) => ({
    design_room_id: currentRoomTypeIdSelector(room),
    filters: filterSelector(filter),
    isAuthenticated: authReducer.isAuthenticated,
    prevUrl: routeReducer.url,
    products: {
      loaded: products.loaded,
      loading: products.loading,
      productsData: productsSelector(products.products),
      tableLoaded: products.tableLoaded,
    },
  }),
  {
    closeModal,
    getAvailableTemplateAction,
    getFilterOptions,
    getProducts,
    push,
    saveRoomTypeId,
    showModal,
  }
)(AddProduct);
