/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import ImageGallery from 'react-image-gallery';

import { Checkbox } from '../Shared';

import { productShape } from '../../shapes';

import './index.scss';

const ProductGallery = ({
  product: {
    id: idProduct,
    default_image,
    name,
    supplier,
    sku,
    cost_cad,
    cost_usd,
    width,
    height,
    depth,
    stock_number,
    last_updated_date,
    url_default_image
  },
  selected,
  handleSelectItems,
  onEdit,
}) => {
  const handleSelect = (id, val) => {
    let selectedItems = selected;
    if (val) {
      selectedItems.push(id);
    } else {
      selectedItems = selectedItems?.filter((user) => user !== id);
    }
    handleSelectItems(selectedItems);
  };
  const { medium: { url: mediumImage } = {} } = default_image;
  const imageUrl = mediumImage || url_default_image;

  const images = [
    {
      original: imageUrl,
    },
  ].filter((image) => image.original);

  return (
    <div className="product-gallery">
      <div className="product-gallery__check">
        <Checkbox
          controlFunc={(val) => handleSelect(idProduct, val)}
          value={Boolean(selected?.find((s) => s === idProduct))}
        />
      </div>
      <div className="product-gallery__image">
        <ImageGallery
          onClick={() => onEdit(idProduct)}
          items={images}
          showPlayButton={false}
        />
      </div>
      <div
        className="product-gallery__info"
        aria-hidden
        onClick={() => onEdit(idProduct)}
      >
        <div className="product-gallery__info-dimensions">
          {`W ${width}” x H ${height}” x D ${depth}”`}
        </div>
        <div className="product-gallery__info-name">{name}</div>
        <div className="product-gallery__info-supplier">
          {supplier || 'No supplier'}
        </div>
        <div className="product-gallery__info-sku">SKU: {sku}</div>
        <div className="product-gallery__info-dimensions-stock">
          Stock: {stock_number}
        </div>
        <div className="product-gallery__info-details">
          <div className="product-gallery__info-cost">
            <div className="product-gallery__info-cost-cad">
              ${cost_cad} CAD
            </div>
            <div className="product-gallery__info-date">
              {format(new Date(last_updated_date), 'MM/dd/yyyy')}
            </div>
            <div className="product-gallery__info-cost-usd">
              ${cost_usd} USD
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ProductGallery;

ProductGallery.propTypes = {
  handleSelectItems: PropTypes.func,
  onEdit: PropTypes.func,
  product: productShape,
  selected: PropTypes.array,
};
