import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { compose } from 'redux';
import Modal from 'react-modal';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { push } from 'react-router-redux';
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from '@react-google-maps/api';
import Switch from 'react-switch';
import { Link } from 'react-router-dom';

import {
  getDeliveryAgentsSummaryRequest,
  getDeliveryAgentsSelector,
  getDeliveryAgentsRequest,
  checkAddressRequest,
  createDeliveryAgentRequest,
} from '../../ducks/deliveryAgents';
import { getToken } from '../../ducks/auth';
import { getAdminUsersRequest, usersReducerSelector } from '../../ducks/users';

import {
  updateDeliveryAgentComponents,
  deliveryAgentObject,
  numberAtributesOnCreateDeliveryAgent,
  renderColumnsDeliveryAgent,
  modalPropsDeliveryAgents,
  customStylesModal,
} from '../../constants/createHelper';
import { deliveryAgentsReducerShapes, historyShapes, usersReducerShape } from '../../constants/shapes';
import { filtersDeliveryAgents } from '../../constants/filters';

import { checkValidDeliveryAgent } from '../../utils/checkValidationOnCreate';

import withModal from '../../Components/HOCs/modal';
import {
  Button,
  Input,
  Select,
  Spinner,
  Table,
} from '../../Components/Shared';
import FilterSidebar from '../../Components/FilterSidebar';

import qs from '../../modules/normalizingQs';

import { GOOGLE_MAPS_API_KEY } from '../../config/external';

import './index.scss';

const DeliveryAgent = ({
  getDeliveryAgentsSummaryRequest: getDeliveryAgents,
  getDeliveryAgentsRequest: getDeliveryAgentsByAddress,
  deliveryAgentsReducer: {
    deliveryAgents,
    isLoaded,
    address,
    addressIsLoading,
    isLoading,
  },
  usersReducer: { adminUsers },
  checkAddressRequest: checkAddress,
  createDeliveryAgentRequest: createDeliveryAgentDispatch,
  getAdminUsersRequest: getAdminUsers,
  token = '',
  push: pushTo,
  history: { location: { search: searchUrl } },
}) => {
  const center = {
    lat: 38.920435,
    lng: -80.341469,
  };

  const {
    country,
    quality_rating,
    status,
    search,
    map_view,
    page,
  } = qs.parse(searchUrl);
  const [actualTab, setActualTab] = useState('summary');
  const [geocodeSearch, setGeocodeSearch] = useState('');
  const [modal, setModal] = useState({
    data: [],
    isOpen: false,
    name: 'quotes',
  });
  const [isMapViewSelected, setIsMapViewSelected] = useState(!!(map_view));
  const [filters, setFilters] = useState({
    country: country || [],
    map_view: isMapViewSelected || false,
    page: parseInt(page, 10) || 1,
    quality_rating: quality_rating || ['1', '5'],
    search: search || '',
    status: status || [],
  });
  const [createDeliveryAgentData, setCreateDeliveryAgentData] = useState(deliveryAgentObject);
  const [activeMarker, setActiveMarker] = useState(null);
  const [centerMap, setCenterMap] = useState(center);

  const { isLoaded: isMapLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    id: 'google-map-script',
  });

  useEffect(() => {
    if (!adminUsers) {
      getAdminUsers();
    }
  }, []);

  useEffect(() => {
    if (!addressIsLoading && address?.lng && address?.lat) {
      setCenterMap({
        lat: address.lat,
        lng: address.lng,
      });
    } else if (!geocodeSearch) {
      setCenterMap(center);
    }
  }, [address]);

  const renderMap = () => (isMapLoaded && !isLoading ? (
    <GoogleMap
      mapContainerStyle={{
        height: '400px',
        width: '100%',
      }}
      center={centerMap}
      zoom={geocodeSearch ? 7 : 4}
    >
      {deliveryAgents.data.map(({
        id,
        company_name,
        latitude: lat,
        longitude: lng,
        address: addressCompany,
        admin_contact_name,
        admin_contact_email,
      }) => (
        <div key={id}>
          <Marker
            position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
            title={company_name}
            onClick={() => setActiveMarker(id)}
          />
          {activeMarker === id && (
            <InfoWindow
              onCloseClick={() => setActiveMarker(null)}
              position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
            >
              <div className="delivery-agent__info">
                <Link className="delivery-agent__info-name" to={`/delivery-agents/${id}`}>
                  {company_name}
                </Link>
                <div className="delivery-agent__info-address">
                  {addressCompany}
                </div>
                <a
                  className="delivery-agent__info-email"
                  href={`mailto:${admin_contact_email})`}
                >
                  {admin_contact_name}
                </a>
              </div>
            </InfoWindow>
          )}
        </div>
      ))}
    </GoogleMap>
  ) : <Spinner />);

  const debounceGeosearch = debounce(() => {
    getDeliveryAgentsByAddress({
      ...filters,
      address: geocodeSearch.trim(),
      page: filters?.page,
    });
  }, 300);

  const debounceCheckAddress = debounce(() => {
    checkAddress(createDeliveryAgentData.address);
  }, 500);

  const handleGetDeliveryAgents = () => {
    const queryParams = qs.stringify({ ...filters, map_view: isMapViewSelected });
    const newFilters = {
      ...filters,
      map_view: isMapViewSelected,
    };

    getDeliveryAgents(newFilters);
    pushTo(`/delivery-agents?${queryParams}`);
  };

  const debounceGetDeliveryAgents = debounce(() => {
    handleGetDeliveryAgents();
  }, 1000);

  useEffect(() => {
    const {
      address: addressFromState,
      country: countryAddress,
      state_or_province,
      longitude,
      latitude,
      currency,
      metropolitan_city,
    } = address;

    setCreateDeliveryAgentData({
      ...createDeliveryAgentData,
      address: addressFromState,
      country: countryAddress,
      currency,
      latitude,
      longitude,
      metropolitan_city,
      state_or_province,
    });
  }, [address]);

  useEffect(() => {
    if (createDeliveryAgentData.address) {
      debounceCheckAddress();
    }

    return () => {
      debounceCheckAddress.cancel();
    };
  }, [createDeliveryAgentData.address]);

  useEffect(() => {
    if (geocodeSearch) {
      debounceGeosearch();
    } else {
      debounceGetDeliveryAgents();
    }

    if (!filters?.search) {
      const queryParams = qs.stringify({ ...filters, page: 1 });

      setFilters({
        ...filters,
        page: 1,
      });

      pushTo(`/delivery-agents?${queryParams}`);
    }

    return () => {
      debounceGetDeliveryAgents.cancel();
      debounceGeosearch.cancel();
    };
  }, [filters?.search]);

  const handleSubmit = useCallback(() => {
    createDeliveryAgentDispatch({
      callback: () => {
        setModal({
          ...modal,
          isOpen: false,
        });
      },
      data: createDeliveryAgentData,
    });
  }, [createDeliveryAgentData]);

  const { search: searchParam, ...filterSidebarParams } = filters;

  const renderContent = useMemo(() => ({
    summary: (
      <div className="delivery-agent__content">
        <div className="delivery-agent__header">
          <div className="delivery-agent__switch-container">
            Map view
            <Switch
              className="delivery-agent__switch"
              onChange={() => {
                setIsMapViewSelected(!isMapViewSelected);
                const newFilters = {
                  ...filters,
                  map_view: !isMapViewSelected,
                  page: 1,
                };
                const queryParams = qs.stringify(newFilters);

                setFilters(newFilters);

                pushTo(`/delivery-agents?${queryParams}`);
              }}
              checked={!isMapViewSelected}
              onColor="#888"
              uncheckedIcon={false}
              checkedIcon={false}
            />
            Table view
          </div>
          <Button
            size="small"
            color="primary"
            type="button"
            onClick={() => setModal({
              ...modal,
              isOpen: true,
              name: 'create',
            })}
          >
            New Delivery Agent
          </Button>
          <Input
            className="delivery-agent__search-geocode"
            name="search-geocode"
            value={geocodeSearch}
            placeholder="Closest to Address or Zip Code"
            onChange={(e) => setGeocodeSearch(e.target.value)}
          />
        </div>
        <Input
          inputClassName="delivery-agent__search-input"
          className="delivery-agent__search-delivery-agents"
          name="search-delivery-agents"
          value={filters?.search}
          placeholder="Search by company name, contact name, contact email address or phone number"
          onChange={(e) => setFilters({
            ...filters,
            search: e.target.value,
          })}
        />
        <div className="delivery-agent__table">
          <FilterSidebar
            source="delivery-agents"
            filters={filtersDeliveryAgents}
            state={filterSidebarParams}
            saveChanges={(e) => {
              setFilters({
                ...e,
                search: searchParam,
              });
            }}
          />
          {isMapViewSelected ? (
            renderMap()
          ) : (
            <Table
              type="deliveryAgentSummary"
              items={deliveryAgents?.data}
              tableLoaded={isLoaded}
              noResults={isLoaded && !deliveryAgents?.data?.length}
              totalEntries={deliveryAgents?.meta?.totalEntries}
              currentPage={parseInt(deliveryAgents?.meta?.page, 10)}
              perPage={deliveryAgents?.meta?.perPage}
              renderColumns={renderColumnsDeliveryAgent}
              handleOpenModal={(data, name) => setModal({
                data,
                isOpen: true,
                name,
              })}
              paginationChange={(actualPage) => {
                setFilters({
                  ...filters,
                  page: actualPage,
                });
              }}
            />
          )}
        </div>
      </div>
    ),
  }), [
    activeMarker,
    deliveryAgents,
    isLoaded,
    geocodeSearch,
    createDeliveryAgentData,
    adminUsers,
    filters,
    searchUrl,
    isMapViewSelected,
  ]);

  const renderModal = useMemo(() => ({
    create: (
      <form className="delivery-agent__form">
        {
          updateDeliveryAgentComponents.map(({
            component,
            name,
            label,
            required,
            disabled,
            placeholder,
            type,
            options,
          }) => {
            const InputComponent = component === 'input' ? Input : Select;

            return (
              <div className="delivery-agent__form-element" key={name}>
                {
                  component === 'select' && (
                    <div className="delivery-agent__form-select">
                      <label className="delivery-agent__form-label" htmlFor={name}>
                        {label}
                        {required && <span className="delivery-agent__form-required">*</span>}
                      </label>
                    </div>
                  )
                }
                <InputComponent
                  key={name}
                  name={name}
                  label={label}
                  disabled={disabled}
                  value={createDeliveryAgentData[name]}
                  onChange={(e) => setCreateDeliveryAgentData({
                    ...createDeliveryAgentData,
                    [name]: (
                      numberAtributesOnCreateDeliveryAgent.includes(name)
                      && (e.target.value < 0 || e.target.value)
                        ? Math.abs(e.target.value, 10)
                        : e.target.value),
                  })}
                  {...(name === 'address' && addressIsLoading && { disabled: true })}
                  {...type && { type }}
                  {...(type === 'number') && { min: 0 }}
                  {...placeholder && { placeholder }}
                  {...(options && {
                    options,
                    placeholder: 'Select an option',
                  })}
                  {...(name === 'ops_owner') && { options: adminUsers }}
                  {...(required && { required })}
                />
              </div>
            );
          })
        }
        <div className="delivery-agent__form-buttons">
          <Button
            color="danger"
            type="button"
            size="large"
            onClick={() => setModal({
              ...modal,
              isOpen: false,
            })}
          >
            Cancel
          </Button>
          <Button
            color="blue"
            type="button"
            size="large"
            disabled={checkValidDeliveryAgent(createDeliveryAgentData)}
            onClick={handleSubmit}
          >
            Create
          </Button>
        </div>
      </form>
    ),
    show: (<Table
      token={token}
      type={`deliveryAgent${modal.name}`}
      items={modal?.data}
      tableLoaded
      noResults={!modal?.data?.length}
      renderColumns={modalPropsDeliveryAgents?.[modal?.name]?.columns}
    />),
  }), [
    createDeliveryAgentData,
    modal,
    addressIsLoading,
    adminUsers,
    filters,
    isMapViewSelected,
  ]);

  useEffect(() => {
    if (geocodeSearch) {
      debounceGeosearch();
    } else {
      handleGetDeliveryAgents();
    }

    return () => debounceGeosearch.cancel();
  }, [
    filters?.page,
    filters?.quality_rating,
    filters?.status,
    filters?.country,
    geocodeSearch,
    isMapViewSelected,
  ]);

  return (
    <div className="delivery-agent">
      <div className="delivery-agent__tabs">
        <div
          aria-hidden
          className={classNames('delivery-agent__tab', { 'delivery-agent__tab--active': actualTab === 'summary' })}
          onClick={() => setActualTab('summary')}
        >
          Delivery Agent Summary
        </div>
      </div>
      <Modal
        isOpen={modal?.isOpen}
        onRequestClose={() => setModal({ data: [], isOpen: false, name: '' })}
        style={customStylesModal}
        contentLabel={modal?.name}
      >
        <div className="modal__title">
          {modalPropsDeliveryAgents?.[modal.name]?.title || 'Create Delivery Agent'}
        </div>
        <div className="modal__content">
          {renderModal?.[modal.name === 'create' ? 'create' : 'show']}
        </div>
      </Modal>
      {renderContent?.[actualTab]}
    </div>
  );
};

DeliveryAgent.propTypes = {
  checkAddressRequest: propTypes.func.isRequired,
  createDeliveryAgentRequest: propTypes.func.isRequired,
  deliveryAgentsReducer: deliveryAgentsReducerShapes.isRequired,
  getAdminUsersRequest: propTypes.func.isRequired,
  getDeliveryAgentsRequest: propTypes.func.isRequired,
  getDeliveryAgentsSummaryRequest: propTypes.func.isRequired,
  history: historyShapes.isRequired,
  push: propTypes.func.isRequired,
  token: propTypes.string,
  usersReducer: usersReducerShape.isRequired,
};

const mapStateToProps = (state) => ({
  deliveryAgentsReducer: getDeliveryAgentsSelector(state),
  token: getToken(state),
  usersReducer: usersReducerSelector(state),
});

const mapDispatchToProps = {
  checkAddressRequest,
  createDeliveryAgentRequest,
  getAdminUsersRequest,
  getDeliveryAgentsRequest,
  getDeliveryAgentsSummaryRequest,
  push,
};

const enhance = compose(
  withModal,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(DeliveryAgent);
