/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { debounce } from 'lodash';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { connect } from 'react-redux';
import CheckboxTree from 'react-checkbox-tree';
import BeatLoader from 'react-spinners/BeatLoader';
import { modalSelector } from '../../ducks/modal';
import { Input } from '../Shared';
import './index.scss';
import API_URL from "../../config/env"
import axios from "axios"
import qs from "qs"

const defaultDimensions = {
  dimension_width: [],
  dimension_height: [],
  dimension_depth: [],
};
const defaultBaseDimensions = {
  base_dimension_width: [],
  base_dimension_height: [],
  base_dimension_depth: [],
}

const FilterSidebar = ({ state, saveChanges, filters, source, urlSearch }) => {
  const apiUrl = API_URL.apiUrl
  const filterCategories = Object?.keys(filters)?.filter((key) => !key.includes('currencies'));
  const isProducts = source === 'products';
  const [actualFilter, setActualFilter] = useState({ ...state });
  const [baselineDimensions, setBaselineDimensions] = useState(defaultBaseDimensions);
  const [selectedDimensions, setSelectedDimensions] = useState(defaultDimensions);
  const [filtersSearch, setFiltersSearch] = useState(filters);
  const [search, setSearch] = useState('');
  const [renderTreeCategories, setRenderTreeCategories] = useState(filterCategories.reduce((acc, key) => ({ ...acc, [key]: false }), {}));
  const [categoriesLoading, setCategoriesLoading] = useState(filterCategories.reduce((acc, key) => ({ ...acc, [key]: false }), {}));
  const [isDimensionsExpanded, setDimensionsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const saveActualFilter = (key, val) => {
    const newState = fromJS(({
      ...actualFilter,
      [key]: val,
    })).toJS();
    setActualFilter(newState);
  };

  const disabled = JSON.stringify(actualFilter) === JSON.stringify(state);
  const filterObjectChildrenNodes = (obj, searchValue, expandedCategories) => obj?.map((item) => {
    if (item) {
      if (searchValue.some((combination) => item?.label?.toLowerCase()?.includes(combination?.toLowerCase()))) {
        return item;
      }

      if (item?.children) {
        const children = filterObjectChildrenNodes(item?.children, searchValue, expandedCategories);
        const filteredChildren = children.filter((child) => child !== undefined);

        if (filteredChildren.length) {
          if (item?.children && searchValue) {
            expandedCategories.push(item?.value.toString());
          }

          return {
            ...item,
            children: filteredChildren,
          };
        }
      }
    }

    return undefined;
  }).filter((item) => item !== undefined);

  const debounceSearch = debounce((searchValue) => {
    const searchValueCombinations = [searchValue, ...searchValue.split(' ')];
    const expandedCategories = [];

    const filteredSearch = Object.keys(filters).reduce((acc, key) => ({
      ...acc,
      [key]: (key !== 'dimensions') ? filterObjectChildrenNodes(filters[key], searchValueCombinations, expandedCategories) : {},
    }), {});


    setActualFilter({
      ...actualFilter,
      expandedCategories,
    });

    setFiltersSearch(
      searchValue ? filteredSearch : filters,
    );
  }, 300);

  useEffect(() => {
    debounceSearch(search);

    return () => debounceSearch.cancel();
  }, [search]);


  if (isProducts){
    useEffect(() => {
      const parsedQuery = qs.parse(urlSearch);
      const hasUrlDimensions =
        parsedQuery.dimension_width &&
        Array.isArray(parsedQuery.dimension_width) &&
        parsedQuery.dimension_width.length > 0 &&
        parsedQuery.dimension_height &&
        Array.isArray(parsedQuery.dimension_height) &&
        parsedQuery.dimension_height.length > 0 &&
        parsedQuery.dimension_depth &&
        Array.isArray(parsedQuery.dimension_depth) &&
        parsedQuery.dimension_depth.length > 0;

      const actualCategories = JSON.stringify(actualFilter.categories || []);
      const propCategories = JSON.stringify(state.categories || []);

      if (hasUrlDimensions && actualCategories === propCategories) {
        setSelectedDimensions({
          dimension_width: state.dimension_width,
          dimension_height: state.dimension_height,
          dimension_depth: state.dimension_depth,
        });
        setBaselineDimensions({
          base_dimension_width: state.base_dimension_width,
          base_dimension_height: state.base_dimension_height,
          base_dimension_depth: state.base_dimension_depth,
        })
        setActualFilter((prev) => ({
          ...prev,
          dimension_width: state.dimension_width,
          dimension_height: state.dimension_height,
          dimension_depth: state.dimension_depth,
          categories: state.categories,
        }));
      } else if (actualCategories !== propCategories) {
        setActualFilter((prev) => ({
          ...prev,
          categories: actualFilter.categories,
        }));

        setSelectedDimensions((prev) => ({
          ...prev,
          dimension_width: baselineDimensions.base_dimension_width,
          dimension_height: baselineDimensions.base_dimension_height,
          dimension_depth: baselineDimensions.base_dimension_depth,
        }));


        fetchDimensions();
      }
    }, [state.categories, actualFilter.categories]);
  }

  useEffect(() => {
    setActualFilter((prev) => ({
      ...prev,
      dimension_width: selectedDimensions.dimension_width,
      dimension_height: selectedDimensions.dimension_height,
      dimension_depth: selectedDimensions.dimension_depth,
      base_dimension_width: baselineDimensions.base_dimension_width,
      base_dimension_depth: baselineDimensions.base_dimension_depth,
      base_dimension_height: baselineDimensions.base_dimension_height,
    }));

  }, [selectedDimensions, baselineDimensions]);

  const fetchDimensions = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: `${apiUrl}products/products_dimensions`,
      data: { categories: actualFilter.categories || [] },
      crossDomain: true,
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        const data = response.data;
        if (data && data.dimensions) {
          setBaselineDimensions( {
            base_dimension_width: data.dimensions.dimension_width,
            base_dimension_height: data.dimensions.dimension_height,
            base_dimension_depth: data.dimensions.dimension_depth,
          });
          setSelectedDimensions({
            dimension_width: data.dimensions.dimension_width,
            dimension_height: data.dimensions.dimension_height,
            dimension_depth: data.dimensions.dimension_depth,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching dimensions:', error);
      });
  };

  const handleAccordionChange = (expandedUuids) => {
    if (expandedUuids.includes('dimensions') && !isDimensionsExpanded) {
      setDimensionsExpanded(true);

      const parsedQuery = qs.parse(urlSearch, { ignoreQueryPrefix: true });
      const hasUrlDimensions =
        parsedQuery.dimension_width &&
        Array.isArray(parsedQuery.dimension_width) &&
        parsedQuery.dimension_width.length > 0 &&
        parsedQuery.dimension_height &&
        Array.isArray(parsedQuery.dimension_height) &&
        parsedQuery.dimension_height.length > 0 &&
        parsedQuery.dimension_depth &&
        Array.isArray(parsedQuery.dimension_depth) &&
        parsedQuery.dimension_depth.length > 0;

      const actualCategories = JSON.stringify(actualFilter.categories || []);
      const propCategories = JSON.stringify(state.categories || []);

      if (!(hasUrlDimensions && actualCategories === propCategories)) {
        setSelectedDimensions((prev) => ({
          ...prev,
          dimension_width: baselineDimensions.base_dimension_width,
          dimension_height: baselineDimensions.base_dimension_height,
          dimension_depth: baselineDimensions.base_dimension_depth,
        }));

        fetchDimensions();
      }
    }
  };

  const renderCategories = useMemo(() => ({
    quality_rating: (
      <div className="filter-sidebar__ranges">
        <div className="filter-sidebar__inputs">
          <Input
            name="minRating"
            type="text"
            placeholder="Min"
            value={actualFilter.quality_rating?.[0] || ''}
            onChange={(event) => setActualFilter({
              ...actualFilter,
              quality_rating: [event.target.value, actualFilter?.quality_rating?.[1]],
            })}
          />
          -
          <Input
            name="maxRating"
            type="text"
            placeholder="Max"
            value={actualFilter.quality_rating?.[1] || ''}
            onChange={(event) => setActualFilter({
              ...actualFilter,
              quality_rating: [actualFilter?.quality_rating?.[0], event.target.value],
            })}
          />
        </div>
      </div>
    ),
    dimension_width: (
      <div className="filter-sidebar__dimensions_ranges">
        <label>Width</label>
        <Slider
          range
          step={1}
          min={Number(baselineDimensions.base_dimension_width[0])}
          max={Number(baselineDimensions.base_dimension_width[1])}
          value={[
            Number(selectedDimensions.dimension_width[0]),
            Number(selectedDimensions.dimension_width[1]),
          ]}
          onChange={(newValue) =>
            setSelectedDimensions((prev) => ({
              ...prev,
              dimension_width: newValue,
            }))
          }
        />
        <div className="filter-sidebar__range-values">
          <div className="filter-sidebar__range-min-wrapper">
            <span className="filter-sidebar__range-min-value">
              {Number(selectedDimensions.dimension_width[0]) + '"'}
            </span>
          </div>
          <div className="filter-sidebar__range-delimiter-wrapper">
            <span className="filter-sidebar__range-delimiter">
              —
            </span>
          </div>
          <div className="filter-sidebar__range-max-wrapper">
            <span className="filter-sidebar__range-max-value">
              {Number(selectedDimensions.dimension_width[1]) + '"'}
            </span>
          </div>
        </div>
      </div>
    ),
    dimension_height: (
      <div className="filter-sidebar__dimensions_ranges">
        <label>Height</label>
        <Slider
          range
          step={1}
          min={Number(baselineDimensions.base_dimension_height[0])}
          max={Number(baselineDimensions.base_dimension_height[1])}
          value={[
            Number(selectedDimensions.dimension_height[0]),
            Number(selectedDimensions.dimension_height[1]),
          ]}
          onChange={(newValue) =>
            setSelectedDimensions((prev) => ({
              ...prev,
              dimension_height: newValue,
            }))
          }
        />
        <div className="filter-sidebar__range-values">
          <div className="filter-sidebar__range-min-wrapper">
            <span className="filter-sidebar__range-min-value">
              {Number(selectedDimensions.dimension_height[0]) + '"'}
            </span>
          </div>
          <div className="filter-sidebar__range-delimiter-wrapper">
            <span className="filter-sidebar__range-delimiter">
              —
            </span>
          </div>
          <div className="filter-sidebar__range-max-wrapper">
            <span className="filter-sidebar__range-max-value">
              {Number(selectedDimensions.dimension_height[1]) + '"'}
            </span>
          </div>
        </div>
      </div>
    ),
    dimension_depth: (
      <div className="filter-sidebar__dimensions_ranges">
        <label>Depth</label>
        <Slider
          range
          step={1}
          min={Number(baselineDimensions.base_dimension_depth[0])}
          max={Number(baselineDimensions.base_dimension_depth[1])}
          value={[
            Number(selectedDimensions.dimension_depth[0]),
            Number(selectedDimensions.dimension_depth[1]),
          ]}
          onChange={(newValue) =>
            setSelectedDimensions((prev) => ({
              ...prev,
              dimension_depth: newValue,
            }))
          }
        />
        <div className="filter-sidebar__range-values">
          <div className="filter-sidebar__range-min-wrapper">
            <span className="filter-sidebar__range-min-value">
              {Number(selectedDimensions.dimension_depth[0]) + '"'}
            </span>
          </div>
          <div className="filter-sidebar__range-delimiter-wrapper">
            <span className="filter-sidebar__range-delimiter">
              —
            </span>
          </div>
          <div className="filter-sidebar__range-max-wrapper">
            <span className="filter-sidebar__range-max-value">
              {Number(selectedDimensions.dimension_depth[1]) + '"'}
            </span>
          </div>
        </div>
      </div>
    ),
  }), [actualFilter, baselineDimensions, selectedDimensions]);

  const renderCheckCategories = useMemo(() => filterCategories.map((category) => (filtersSearch?.[category]?.length || category === 'quality_rating') ? (
    <AccordionItem
      key={category}
      onClick={() => {
        setCategoriesLoading({ ...categoriesLoading, [category]: true });
        setTimeout(() => {
          setRenderTreeCategories({ ...renderTreeCategories, [category]: !renderTreeCategories[category] });
          setCategoriesLoading({ ...categoriesLoading, [category]: false });
        }, 0);
      }}
    >
      <AccordionItemHeading>
        <AccordionItemButton>
          {(category.charAt(0).toUpperCase() + category.slice(1))?.replace('_', ' ')}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <div className="filter-sidebar__content">
          {categoriesLoading[category] && (
            <div className="filter-sidebar__loader">
              <BeatLoader color="#000" />
            </div>
          )}
          <CheckboxTree
            nodes={filtersSearch[category]}
            checked={actualFilter[category]}
            expanded={actualFilter.expandedCategories}
            expandOnClick
            onlyLeafCheckboxes={false}
            onCheck={(value) => saveActualFilter(category, value)}
            onExpand={(value) => saveActualFilter('expandedCategories', value)}
          />
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  ) : null), [actualFilter, filtersSearch, renderTreeCategories, categoriesLoading]);

  return (
    <div className="filter-sidebar">
      <button
        disabled={disabled}
        onClick={() => saveChanges(actualFilter)}
        className="filter-sidebar__button"
      >
        Apply filters
      </button>
      <Input
        name="filters"
        type="text"
        placeholder="Search filters"
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        onChange={handleAccordionChange}
      >
        {renderCheckCategories}
        {isProducts ? (
          <AccordionItem uuid="dimensions">
            <AccordionItemHeading>
              <AccordionItemButton>
                Product Dimensions
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {loading ? (
                <div className="filter-sidebar__loader">
                  <BeatLoader color="#000" />
                </div>
              ) : (
                <>
                  {renderCategories.dimension_width}
                  {renderCategories.dimension_height}
                  {renderCategories.dimension_depth}
                </>
              )}
            </AccordionItemPanel>
          </AccordionItem>
          ): null
        }
      </Accordion>
    </div>
  );
};

FilterSidebar.propTypes = {
  filters: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })),
  }).isRequired,
  saveChanges: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
};

export default connect(
  (state) => modalSelector(state),
)(FilterSidebar);
